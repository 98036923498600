import React from "react"
import { useStaticQuery, graphql } from "gatsby"

type Meta = {
  name?: string
  property?: string
  content?: string
}

type Props = {
  lang?: string
  title?: string
  description?: string
  meta?: Meta[]
}

const SEO: React.FC<Props> = ({
  lang = "ja",
  title,
  description,
  meta = [],
}) => {
  const { site } = useStaticQuery(graphql`
    query SeoPage {
      site {
        siteMetadata {
          title
          description
          social {
            twitter
          }
        }
      }
    }
  `)

  description = description || site.siteMetadata.description
  title = title || site.siteMetadata.title

  // map for meta tag components
  meta = meta!.concat([
    {
      name: `description`,
      content: description,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: description,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.social.twitter,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: description,
    },
    {
      name: "google-site-verification",
      content: "EPIK6QbSwqtvimBsyJQ_U9WAnKghZw0DFMnGQzxsDvI",
    },
  ])
  const metaComponents = meta.map(m => {
    return <meta key={m.name} {...m} />
  })

  return (
    <>
      <html lang={lang}></html>
      <script
        async
        src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
      ></script>
      {metaComponents}
      <title>{title}</title>
      <link rel="canonical" href="canonical"></link>
    </>
  )
}

export default SEO
