import React from "react"
import { Link, graphql, PageProps } from "gatsby"
import kebabCase from "lodash/kebabCase"

import SEO from "../components/seo"
import { TagPageQuery } from "../../graphql-types"

const Tags: React.FC<PageProps<TagPageQuery>> = ({ data }) => {
  let tagGroup = data.allMdx.group
  const meta = [{ name: "robots", content: "noindex" }]

  let sortedTag = tagGroup.sort(
    (a, b) => 0 - (a.totalCount > b.totalCount ? 1 : -1),
  )
  return (
    <div>
      <SEO title="All tags" meta={meta} />
      {sortedTag.map(tag => (
        <div key={tag.fieldValue}>
          <Link to={`/tags/${kebabCase(tag.fieldValue!)}/`}>
            🔖{tag.fieldValue} ({tag.totalCount})
          </Link>
        </div>
      ))}
    </div>
  )
}

export default Tags

export const pageQuery = graphql`
  query TagPage {
    allMdx(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`
